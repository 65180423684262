import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
// import { Redirect } from '@reach/router';
import { BodyContent, SEO, BasicPageTopper, CTA, Login } from 'components';
import { ThemeContext } from 'utils/constants';
import {
  menuPropTypes,
  carouselItemsPropTypes,
  ctaPropTypes,
  imagePropTypes,
} from 'utils/proptypes';
import { isLoggedIn } from '../../services/auth';
import usePressReleases from 'hooks/usePressReleases';

const LandingPage = ({
  pageContext: { isPrivate, password },
  data: {
    page: { seo, cta, title, content, hideFooterCta },
    menu,
    pages,
    loginPage,
    global,
  },
  location,
}) => {
  const context = useContext(ThemeContext);
  const updatedContent = usePressReleases(content, pages, location.pathname);

  const shouldRenderLogin = isPrivate && !isLoggedIn(password);

  const isMicrosite = global?.siteContext && global?.siteContext[0] === 'Micro Site';

  if (shouldRenderLogin) {
    return (
      <div className={context} style={{ backgroundColor: 'transparent' }}>
        <Helmet
          bodyAttributes={{
            class: 'has-animation',
          }}
        />
        <BasicPageTopper title={loginPage.title} intro={{ intro: `${loginPage.intro}` }} />
        <SEO title="Login" pathname={location.pathname} />
        <BodyContent content={null} location={location} isMicrosite={isMicrosite}>
          <Login
            passwordMatch={password}
            carousel={loginPage.carousel}
            mediaLayout={loginPage.image}
          />
        </BodyContent>
      </div>
    );
  }

  return (
    <div className={context}>
      <Helmet
        bodyAttributes={{
          class: 'has-animation landing-page',
        }}
      />
      <SEO title={title} {...seo} pathname={location.pathname} />
      {content && (
        <BodyContent
          content={updatedContent}
          menu={menu}
          location={location}
          isMicrosite={isMicrosite}
        />
      )}
      {!hideFooterCta && <CTA {...cta} onFooter />}
    </div>
  );
};

LandingPage.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      title: PropTypes.string.isRequired,
      overline: PropTypes.string,
      featuredImage: imagePropTypes,
      featuredImageHorizontal: PropTypes.bool,
      intro: PropTypes.shape({
        intro: PropTypes.string.isRequired,
      }),
      content: PropTypes.shape({
        json: PropTypes.object.isRequired,
      }),
      seo: PropTypes.object,
      cta: ctaPropTypes,
      hideFooterCta: PropTypes.bool,
    }).isRequired,
    menu: menuPropTypes,
    pages: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            createdAt: PropTypes.string,
            slug: PropTypes.string,
            title: PropTypes.string,
          }),
        }),
      ),
    }),
    loginPage: PropTypes.shape({
      title: PropTypes.string,
      intro: PropTypes.string,
      image: imagePropTypes,
      carousel: PropTypes.shape({
        title: PropTypes.string,
        name: PropTypes.string,
        description: PropTypes.shape({
          description: PropTypes.string,
        }),
        carouselItems: carouselItemsPropTypes,
      }),
    }),
    global: PropTypes.shape({
      siteContext: PropTypes.array,
    }),
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  pageContext: PropTypes.object,
};

export default LandingPage;

export const query = graphql`
  query LandingPageQuery($slug: String!) {
    page: contentfulLandingPage(slug: { eq: $slug }) {
      title
      intro {
        intro
      }
      featuredImage {
        description
        title
        file {
          url
        }
      }
      featuredImageHorizontal
      content {
        json
      }
      cta {
        ...CTA
      }
      seo {
        ...SEO
      }
      hideFooterCta
    }
    menu: contentfulMenuList(pages: { elemMatch: { slug: { eq: $slug } } }) {
      heading
      headingUrl
      tanBackgroundUse
      list {
        json
      }
    }
    pages: allContentfulLandingPage(limit: 1000) {
      edges {
        node {
          slug
          createdAt(formatString: "DD/MM/YYYY")
          title
        }
      }
    }
    loginPage: contentfulLoginPage {
      intro
      title
      image {
        link
        title: name
        media {
          file {
            url
          }
          description
          title
        }
      }
      carousel {
        title
        name
        description {
          description
        }
        carouselItems {
          image {
            file {
              url
            }
            description
            title
          }
          caption {
            json
          }
          link
          title
          subtitle
          summary
        }
        isPathwayCarousel
      }
    }
    global: contentfulGlobal {
      siteContext
    }
  }
`;
